import React from "react"
import { Script } from "gatsby"
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import { getBlogLogo, getCookies, getCalendlyLink, getSiteUrl } from '../utils/general/links'

export default function Seo({ children, location }) {
  const {
    allTag: { nodes: tags },
  } = useStaticQuery(graphql`
    {
      allTag {
        nodes {
          id
          url
          title
          description
          image
        }
      }
    }
  `);

  const page = tags.find((tag) => {
    const pathName = location.pathname;
    const pathNameLength = location.pathname.length;
    const language = `${process.env.GATSBY_LANGUAGE_SELECTED}`;
    const siteUrl = getSiteUrl(language);

    if (pathName === "/") return tag.url === new URL(siteUrl).origin;

    const path1 = pathName;
    const path2 = pathName.endsWith("/")
      ? pathName.slice(0, pathNameLength - 1)
      : `${pathName}/`;
    return tag.url.endsWith(path1) || tag.url.endsWith(path2);
  }) ?? {
    title: "Fastest growing Material Informatics platform | Polymerize",
    description:
      "Polymerize is a #1 Material informatics platform that helps gather all your research & development info for data analysis to accelerate research process.",
    image: `${
      getBlogLogo(process.env.GATSBY_LANGUAGE_SELECTED)
    }`,
    url: location.href,
  };

  const isSSR = typeof window === "undefined";

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `${process.env.GATSBY_LANGUAGE_SELECTED}`,
        }}
      >
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        {/* <!-- Primary Meta Tags --> */}
        <title>{page.title}</title>
        <meta name="title" content={page.title} />
        <meta name="description" content={page.description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {process.env.GATSBY_LANGUAGE_SELECTED !== "kr" && ( 
          <meta name="author" content="Polymerize" />
        )}
        {/* <!--  Social tags --> */}
        {/* <meta name="keywords" content="materials science, polymers, informatics" /> */}
        {/* <!-- Schema.org markup for Google+ --> */}
        <meta itemprop="name" content={page.title} />
        <meta itemprop="description" content={page.description} />
        <meta itemprop="image" content={page.image} />
        {/* <!-- Open Graph data --> */}
        {/* <!-- <meta property="fb:app_id" content="214738555737136" /> --> */}
        {location.pathname.includes("holiday-crosswords") ? (
          <meta property="og:title" content="Polymerize - Crossword Puzzle" />
        ) : (
          <meta property="og:title" content={page.title} />
        )}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={page.url} />
        {process.env.GATSBY_LANGUAGE_SELECTED !== "kr" && ( 
           <meta property="og:site_name" content="Polymerize.io" />
        )}
        {location.pathname.includes("holiday-crosswords") ? (
          <meta
            name="image"
            property="og:image"
            content="https://polymerize.io/images/crossword-puzzle.jpg"
          />
        ) : (
          <meta name="image" property="og:image" content={page.image} />
        )}
        <meta property="og:image:alt" content={page.title} />
        {location.pathname.includes("holiday-crosswords") ? (
          <meta
            property="og:description"
            content=":confetti_ball: :gift: It's the season of Joy and Gifting! :gift: :confetti_ball:
:sparkles: Join us to play an interesting CROSSWORD PUZZLE and win AMAZON GIFT VOUCHERS! :sparkles:
Head on to our page link:"
          />
        ) : (
          <meta property="og:description" content={page.description} />
        )}
        {/* <!-- Twitter Card data --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@polymerize_lab" />
        <meta name="twitter:title" content={page.title} />
        <meta name="twitter:description" content={page.description} />
        <meta name="twitter:creator" content="@polymerize_lab" />
        <meta name="twitter:image" content={page.image} />
        <meta name="twitter:image:alt" content={page.title} />
        {/* <!-- Favicon --> */}
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://polymerize.io/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://polymerize.io/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://polymerize.io/favicon/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="https://polymerize.io/favicon/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="https://polymerize.io/favicon/safari-pinned-tab.svg"
          color="#ffffff"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="preload"
          href="https://assets.calendly.com/assets/external/widget.css"
          as="style"
          onLoad="this.rel='stylesheet'"
        />
        <script
          async
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
        ></script>
        <meta name="google-site-verification" content="0Lf-Dcmp83yhUauc9Arj1_0IoJ9Pc29HUQJpYv4gc8Y" />
        {/* GDPR */}
        <script
          async
          type="text/javascript"
          charset="UTF-8"
          src={
            getCookies(process.env.GATSBY_LANGUAGE_SELECTED)
          }
        ></script>
        <Script type="text/javascript" strategy="idle">
          {(() => {
            if (!isSSR) {
              (function (l) {
                let _linkedin_partner_id = "4175169";
                window._linkedin_data_partner_ids =
                  window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                if (!l) {
                  window.lintrk = function (a, b) {
                    window.lintrk.q.push([a, b]);
                  };
                  window.lintrk.q = [];
                }
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";
                b.async = true;
                b.src =
                  "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
              })(window.lintrk);
            }
          })()}
        </Script>
        <Script
          strategy="off-main-thread"
          src="https://fast.wistia.com/embed/medias/l8avs2pyoa.jsonp"
        ></Script>
        <Script
          strategy="off-main-thread"
          src="https://fast.wistia.com/assets/external/E-v1.js"
        ></Script>
        <Script type="text/javascript" strategy="off-main-thread">
          {(() => {
            if (!isSSR) {
              (function (c, l, a, r, i, t, y) {
                c[a] =
                  c[a] ||
                  function () {
                    (c[a].q = c[a].q || []).push(arguments);
                  };
                t = l.createElement(r);
                t.async = 1;
                t.src = "https://www.clarity.ms/tag/" + i;
                y = l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t, y);
              })(window, document, "clarity", "script", "agpt2o2eyk");
            }
          })()}
        </Script>
        <Script
          type="text/javascript"
          id="hs-script-loader"
          src="//js.hs-scripts.com/8531037.js"
          strategy="off-main-thread"
        />
        {/* GOOGLE TAG MANAGER */}
        {process.env.GATSBY_LANGUAGE_SELECTED !== "ja" && (
          <Script
            strategy="off-main-thread"
            src="https://www.googletagmanager.com/gtag/js?id=GTM-KJCV59W"
            forward={[`dataLayer.push`]}
          />
        )}
        {process.env.GATSBY_LANGUAGE_SELECTED !== "ja" && (
          <Script id="gtm-init" strategy="off-main-thread">
            {(function () {
              if (!isSSR) {
                window.dataLayer = window.dataLayer || [];
                window.gtag = function () {
                  window.dataLayer.push(arguments);
                };
                window.gtag("js", new Date());

                // window.gtag('config', 'G-X0P59YMCB3');
                // window.gtag('config', 'UA-228784423-1');

                (function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js",
                  });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l !== "dataLayer" ? "&l=" + l : "";
                  j.async = true;
                  j.src =
                    "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, "script", "dataLayer", "GTM-KJCV59W");
              }
            })()}
          </Script>
        )}
        {process.env.GATSBY_LANGUAGE_SELECTED === "ja" && (
          <Script
            strategy="off-main-thread"
            src="https://www.googletagmanager.com/gtag/js?id=GTM-NPHXXFGS"
            forward={[`dataLayer.push`]}
          />
        )}
        {process.env.GATSBY_LANGUAGE_SELECTED === "ja" && (
          <Script id="gtm-init" strategy="off-main-thread">
            {(function () {
              if (!isSSR) {
                window.dataLayer = window.dataLayer || [];
                window.gtag = function () {
                  window.dataLayer.push(arguments);
                };
                window.gtag("js", new Date());

                // window.gtag('config', 'G-X0P59YMCB3');
                // window.gtag('config', 'UA-228784423-1');

                (function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js",
                  });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l !== "dataLayer" ? "&l=" + l : "";
                  j.async = true;
                  j.src =
                    "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, "script", "dataLayer", "GTM-NPHXXFGS");
              }
            })()}
          </Script>
        )}
        {process.env.GATSBY_LANGUAGE_SELECTED === "ja" && (
          <>
            <Script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-48DNTTXTM5"
            ></Script>
            <Script>
              {(function () {
                if (!isSSR) {
                  window.dataLayer = window.dataLayer || [];
                  window.gtag = function () {
                    window.dataLayer.push(arguments);
                  };
                  window.gtag("js", new Date());
                  window.gtag("config", "G-48DNTTXTM5");
                }
              })()}
            </Script>
            <meta name="google-site-verification" content="0Lf-Dcmp83yhUauc9Arj1_0IoJ9Pc29HUQJpYv4gc8Y" />
          </>
        )}
        <Script strategy="off-main-thread">
          {(() => {
            if (!isSSR) {
              window.requestADemo = function () {
                window.gtag("event", "conversion", {
                  send_to: "AW-10853993658/uA0tCPvxlrsDELqZy7co",
                });

                window.Calendly.initPopupWidget({
                  url: getCalendlyLink(process.env.GATSBY_LANGUAGE_SELECTED)
                });
              };
            }
          })()}
        </Script>
        ;{" "}
        <Script strategy="off-main-thread">
          {(() => {
            if (!isSSR) {
              window.trackWhitePaperDownload = function () {
                window.lintrk("track", { conversion_id: 9429505 });
                window.lintrk("track", { conversion_id: 8517978 });
                console.log("NEW DOWNLOAD WHITE PAPER TRACKED! 🚀");
              };
            }
          })()}
        </Script>
        <meta
          name="google-site-verification"
          content="ClfsxtX5agjzVUX8Qy7ocqRPkwVPmQ2THv-tHZ3loUw"
        />
        
      </Helmet>
      {process.env.GATSBY_LANGUAGE_SELECTED === "kr" && ( 
          <Helmet>
            <meta name="naver-site-verification" content="aa08d568cdfde40425c163b78303001df6e190f0" />
            <meta name="google-site-verification" content="f8YBJjGwK8PruOzsRbnauVn1GYPtjzJhgSyAz_3TVUI" />
            <link rel="canonical" href="https://polymerize.co.kr/" />
            <meta name="robots" content="index,follow" />
            <meta name="subject" content="Polymerize 폴리마이즈" />
            <meta name="author" content="Polymerize 폴리마이즈"></meta>

            <meta name="keywords" content="Polymerize,폴리마이즈." />

            <meta property="og:rich_attachment" content="true" />
            <meta property="og:site_name" content="Polymerize 폴리마이즈" />
            <meta property="og:image" content="https://polymerize.co.kr/images/Original-Black.png" />
            <meta property="og:url" content="https://polymerize.co.kr/" />
          </Helmet>
        )}
        {process.env.GATSBY_LANGUAGE_SELECTED === "zh" && (
          <Helmet>
            <meta name="baidu-site-verification" content="codeva-ztFVe7RHwk" />
          </Helmet>
        )}

      <main>
        <h1 style={{ display: 'none' }}>{page.title}</h1>
        {children}
      </main>
    </>
  );
}
